import MyTvStakingABI from "../contracts/MyTvStaking_ABI.json";
import MyTvGovernanceABI from "../contracts/MyTvGovernance_ABI.json";
import MyTvFarmingABI from "../contracts/MyTvFarming_ABI.json";
import MyTvPublicSaleABI from "../contracts/MyTvPublicSale_ABI.json";
import MyTvLockABI from "../contracts/MyTvLock_1.0_ABI.json";
import MyTvLockPrivateABI from "../contracts/MyTvLockPrivate_1.0.ABI.json";
import {
  FARMING_CONTRACT_ADDRESS,
  GOVERNANCE_CONTRACT_ADDRESS,
  PUBLIC_SALE_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
  VESTING_LOCK_ADDRESS,
  VESTING_PRIVATE_LOCK_ADDRESS,
} from "../constants";

let governanceTokenContract = null;
export function makeGovernanceTokenContract(web3) {
  governanceTokenContract = governanceTokenContract ?? new web3.eth.Contract(MyTvGovernanceABI, GOVERNANCE_CONTRACT_ADDRESS);
  return governanceTokenContract;
}

let stakingContract = null;
export function makeStakingContract(web3) {
  stakingContract = stakingContract ?? new web3.eth.Contract(MyTvStakingABI, STAKING_CONTRACT_ADDRESS);
  return stakingContract;
}

let farmingContract = null;
export function makeFarmingContract(web3) {
  farmingContract = farmingContract ?? new web3.eth.Contract(MyTvFarmingABI, FARMING_CONTRACT_ADDRESS);
  return farmingContract;
}

let publicSaleContract = null;
export function makePublicSaleContract(web3) {
  publicSaleContract = publicSaleContract ?? new web3.eth.Contract(MyTvPublicSaleABI, PUBLIC_SALE_CONTRACT_ADDRESS);
  return publicSaleContract;
}

let vestingLockContract = null;
export function makeVestingLockContract(web3) {
  vestingLockContract = vestingLockContract ?? new web3.eth.Contract(MyTvLockABI, VESTING_LOCK_ADDRESS);
  return vestingLockContract;
}

let vestingPrivateLockContract = null;
export function makeVestingPrivateLockContract(web3) {
  vestingPrivateLockContract = vestingPrivateLockContract ?? new web3.eth.Contract(MyTvLockPrivateABI, VESTING_PRIVATE_LOCK_ADDRESS);
  return vestingPrivateLockContract;
}
