import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row } from "antd";
import wordReducing from "../../utils/helpers/wordReducing";
import { PUBLIC_SALE_CONTRACT_ADDRESS } from "../../utils/constants";

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding-bottom: 30px;
`;

const StyledDetailsHeader = styled.div`
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  img {
    width: 100%;
    height: auto;
  }
`;

const StyledDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.div`
  margin: 24px 0 16px;
  font: normal normal bold 26px/39px Circe;
  color: #2e86fa;
  text-align: center;
`;

const StyledDetailItem = styled.div`
  width: 80%;
`;

const StyledUnderline = styled.span`
  text-decoration: underline;
`;

const StyledContractInfoItem = styled(Row)`
  width: 100%;
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: #121b3e;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  background: ${({ theme }) => theme.bg5} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 55px;
  margin-top: 12px;
  word-break: break-all;
`;

const StyledContractInfoItemTitle = styled.div`
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  justify-self: center;
`;

const StyledContractInfoItemInfo = styled.div`
  text-align: right;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  justify-self: center;
`;

const StyledSeparator = styled.div`
  width: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.fg1};
  opacity: 0.65;
`;
export default function SaleOverDetails({
  maxBnbPhaseOne,
  maxBnbPhaseTwo,
  bnbSend,
  tokenPrice,
  style,
  className,
}) {
  return (
    <StyledWrapper style={style} className={className}>
      <StyledDetailsHeader>
        <img src="/images/jumpman.png" alt="" />
      </StyledDetailsHeader>
      <StyledDetailsBody>
        <StyledTitle>Sale over !</StyledTitle>
        <StyledDetailItem>
          {[
            [
              "Contract address",
              <StyledUnderline style={{cursor: "pointer"}} onClick={() => navigator.clipboard.writeText(PUBLIC_SALE_CONTRACT_ADDRESS)}>
                {wordReducing(PUBLIC_SALE_CONTRACT_ADDRESS)}
              </StyledUnderline>,
            ],
            ["Max BNB phase 1", `${maxBnbPhaseOne}`],
            ["Max BNB phase 2", `${maxBnbPhaseTwo}`],
            ["BNB sent", <StyledUnderline>{bnbSend ? parseFloat(bnbSend).toFixed(4): null}</StyledUnderline>],
            ["Token price", tokenPrice],
          ].map((e, i) => (
            <StyledContractInfoItem key={i}>
              <StyledContractInfoItemTitle>{e[0]}</StyledContractInfoItemTitle>
              <StyledSeparator />
              <StyledContractInfoItemInfo>{e[1]}</StyledContractInfoItemInfo>
            </StyledContractInfoItem>
          ))}
        </StyledDetailItem>
      </StyledDetailsBody>
    </StyledWrapper>
  );
}

SaleOverDetails.propTypes = {
  maxBnbPhaseOne: PropTypes.string,
  maxBnbPhaseTwo: PropTypes.string,
  bnbSend: PropTypes.string,
  tokenPrice: PropTypes.number,
  style: PropTypes.any,
  className: PropTypes.string,
};
