import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import inconsistentColors from "../utils/helpers/inconsistentColors";

const StyledDashboardCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${inconsistentColors("#F7F9FD", "bg2")} 0% 0% no-repeat
    padding-box;
  border-radius: 15px;
`;

const StyledTitle = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.fg3};
  font: normal normal bold 18px/26px Circe;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContent = styled.div`
  margin-top: 20px;
  width: 100%;
`;

function DashboardCard({ children, title, style, titleStyle }) {
  return (
    <StyledDashboardCard style={style}>
      <StyledTitle style={titleStyle}>{title}</StyledTitle>
      <StyledContent>{children}</StyledContent>
    </StyledDashboardCard>
  );
}

DashboardCard.propTypes = {
  children: Proptypes.any,
  title: Proptypes.any,
  style: Proptypes.any,
  titleStyle: Proptypes.any,
};

export default DashboardCard;
