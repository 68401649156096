import { Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Proptypes from "prop-types";

import { Link } from "react-router-dom";
import styled from "styled-components";
import * as ethers from "ethers";
import Page from "../lib/Page";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import FarmingCard from "../lib/FarmingCard";
import StartFarmingModal from "../components/farming/StartFarmingModal";
import {
  APP_LINKS,
  FARMING_CONTRACT_ADDRESS,
  MAX_ALLOWANCE,
  PUBLIC_SALE_END_DATE,
} from "../utils/constants";
import inconsistentColors from "../utils/helpers/inconsistentColors";
import { useUserStateContext } from "../components/providers/UserContextProvider";
import {
  makeFarmingContract,
  makeGovernanceTokenContract,
} from "../utils/helpers/makeContracts";
import useUpdateMyTvBalance from "../utils/hooks/useUpdateMyTvBalance";
import { useWeb3StateContext } from "../components/providers/Web3ContextProvier";
import { useMyTvStateContext } from "../components/providers/MyTvContextProvider";

const StyledTitleSection = styled(Row)`
   width: 100%;
   margin-top: 47px;
   margin-bottom: 32px;
   border-bottom: 1px solid rgba(84, 91, 122, 0.65);
 `;
const StyledTitleSectionLeft = styled.div`
   text-align: left;
   font: normal normal bold 22px/32px Circe;
   letter-spacing: 0px;
   color: ${({ theme }) => theme.fg1};
 `;
const StyledTitleSectionRight = styled(Link)`
   text-align: right;
   text-decoration: underline;
   font: normal normal bold 18px/26px Circe;
   letter-spacing: 0px;
   color: ${inconsistentColors("#1F2952", "#F6F9FF")};
   &:hover {
     color: ${inconsistentColors("#1F2952", "#F6F9FF")};
   }
 `;
const StyledPage = styled(Page)`
  background-image: url("/images/grid1.png"), url("/images/dashboard-bg.png");
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: right 70px top 10px, left 5px bottom;
`;
const MainContainer = styled.div`
   width: calc(100% - 32px);
   margin-left: 16px;
   margin-right: 16px;
 `;

const StyledCardContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(3, 361px);
   justify-content: center;
   align-items: center;
   justify-items: center;
   gap: 84px;
   padding-bottom: 50px;
 `;
const StyledComingSoonCard = styled.div`
  margin-top: 40px;
  height: 545px;
  background-image: url(${({ $bg }) => $bg});
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  border-radius: 20px;
`;
const StlyedTxt = styled.div`
  position: absolute;
  text-align: left;
  font: normal normal bold 62px/91px Circe;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  top: 326px;
  left: 124px;
`;
const StyledColoredText = styled.div`
  position: absolute;
  top: 200px;
  left: 124px;
  text-align: left;
  font: normal normal bold 72px/106px Circe;
  color: #55c0f0;
  width: 455px;
  border-bottom: 1px solid #55c0f0;
`;
function ComingSoonCard({ text, backgroundImage }) {
  return (
    <StyledComingSoonCard $bg={backgroundImage}>
      <StyledColoredText>{text}</StyledColoredText>
      <StlyedTxt>
        Coming
        <div style={{ marginLeft: 150 }}>Soon</div>
      </StlyedTxt>
    </StyledComingSoonCard>
  );
}
ComingSoonCard.propTypes = {
  backgroundImage: Proptypes.string,
  text: Proptypes.string,
};

let intervalTimerId = 0;

function Farming() {

  const {
    farmingApr,
    farmingApy,
    tvl,
  } = useMyTvStateContext();

  const [modalsConfirmOpen, setModalsConfirmOpen] = useState({});
  const { activeAccount } = useUserStateContext();
  const { web3 } = useWeb3StateContext();
  const [isLoading, setIsLoading] = useState({});
  const updateMyTvBalance = useUpdateMyTvBalance();
  const [initialAllowance, setInitialAllowance] = useState(MAX_ALLOWANCE);
  const governanceContract = useMemo(
    () => (web3 ? makeGovernanceTokenContract(web3) : null),
    [web3]
  );

  const farmingContract = useMemo(
    () => (web3 ? makeFarmingContract(web3) : null),
    [web3]
  );

  useEffect(() => {
    if (!governanceContract || !activeAccount) return;
    (async function getInitialBalance() {
      setInitialAllowance(
        parseInt(
          await governanceContract.methods
            .allowance(activeAccount, FARMING_CONTRACT_ADDRESS)
            .call(),
          10
        )
      );
    })();
  }, [governanceContract, activeAccount]);

  const [canUseView, setCanUseView] = useState(PUBLIC_SALE_END_DATE <= new Date());
  useEffect(() => {
    window.clearInterval(intervalTimerId);
    if (!canUseView) {
      intervalTimerId = window.setInterval(() => {
        if (PUBLIC_SALE_END_DATE <= new Date()) {
          window.clearInterval(intervalTimerId);
          setCanUseView(true);
        }
      }, 500);
    }

    return () => {
      window.clearInterval(intervalTimerId);
    }
  });

  const [mytvPerBlock, setMytvPerBlock] = useState("N/A");
  (async () => {
    if (!farmingContract) return;
    const rewards = await farmingContract.methods.mytvPerBlock().call();
    setMytvPerBlock((~~ethers.utils.formatUnits(rewards, 4)).toString());
  })();

  const cards = useMemo(
    () => {
      return [
        {
          icon1: "/exchanges/mytv.png",
          icon2: "/exchanges/bnb.png",
          title: "MYTV-BNB",
          apy: `${farmingApy ?? "...."}`,
          apr: `${farmingApr ?? "...."}`,
          tvl: `$${(parseFloat(tvl)).toFixed(0)}`,
          multiple: 40,
        },
      ];
    },
    [farmingApr, farmingApy, tvl]
  );

  const comingSoonElement = <ComingSoonCard backgroundImage="/images/farming-cs.png" text="FARMING" />;

  const viewElement = (
    <MainContainer>
      <StyledTitleSection justify="space-between" align="middle">
        <StyledTitleSectionLeft>Your Farming Offers</StyledTitleSectionLeft>
        <StyledTitleSectionRight to={APP_LINKS.STAKING_DETAILS}>
          See my farming details
        </StyledTitleSectionRight>
      </StyledTitleSection>

      <StyledCardContainer>
        {cards.map((e, id) => {
          const setConfirmModalOpen = (v) =>
            setModalsConfirmOpen((o) => ({ ...o, [id]: v }));
          return (
            <React.Fragment key={id}>
              <FarmingCard
                icon1={e.icon1}
                icon2={e.icon2}
                title={e.title}
                apy={`${e.apy} %`}
                apr={`${e.apr} %`}
                rewards={mytvPerBlock}
                tvl={e.tvl}
                textButton="FARM"
                textModal={e.textModal}
                onBtnClick={() => {
                  if (!web3) return;
                  setConfirmModalOpen(true);
                }}
                multiple={e.multiple}
                style={
                  cards.length === 1
                    ? {
                      gridColumn: 2,
                    }
                    : {}
                }
              />
              {modalsConfirmOpen[id] && (
                <StartFarmingModal
                  open
                  onCancel={() => {
                    setConfirmModalOpen(false);
                  }}
                  initialAllowance={initialAllowance}
                  width={560}
                  title="Start earning rewards"
                  balance="120"
                  apr={e.apr}
                  loading={isLoading[id]}
                  onSuccess={async ({ amount, onAllowed }) => {
                    try {
                      const amountBlkChain = ethers.utils.parseUnits(
                        amount,
                        // "0.0002",
                        4
                      );

                      setIsLoading((o) => ({ ...o, [id]: true }));

                      const myTvBal = await governanceContract.methods
                        .balanceOf(activeAccount)
                        .call();

                      if (amountBlkChain.gt(myTvBal)) {
                        throw new Error("balance is not enough");
                      }

                      const allowedMyTV = await governanceContract.methods
                        .allowance(activeAccount, FARMING_CONTRACT_ADDRESS)
                        .call();

                      onAllowed();
                      if (amountBlkChain.gt(allowedMyTV)) {
                        await governanceContract.methods
                          .approve(FARMING_CONTRACT_ADDRESS, MAX_ALLOWANCE)
                          .send({ from: activeAccount });
                      }

                      await farmingContract.methods
                        .deposit(0, amountBlkChain)
                        .send({ from: activeAccount });
                      setConfirmModalOpen(false);
                    } catch (err) {
                      console.error(err);
                    } finally {
                      await updateMyTvBalance();
                      setIsLoading((o) => ({ ...o, [id]: false }));
                    }
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </StyledCardContainer>
    </MainContainer>
  );
  return (
    <StyledPage header={<DashboardHeader />}>
      {canUseView ? viewElement : comingSoonElement}
    </StyledPage>
  );
}

export default Farming;
