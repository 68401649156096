/* eslint-disable no-await-in-loop */
import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState, useRef } from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import { ethers } from "ethers";
import { useUserStateContext } from "../providers/UserContextProvider";
import { makeStakingContract, makeVestingLockContract, makeVestingPrivateLockContract } from "../../utils/helpers/makeContracts";
import Table from "../../lib/Table";
import Button from "../../lib/Button";
import useUpdateMyTvBalance from "../../utils/hooks/useUpdateMyTvBalance";
import { SIMPLE_DATE_FORMAT } from "../../utils/constants";
import { useWeb3StateContext } from "../providers/Web3ContextProvier";
import VestingAddressList from '../../utils/VestingAddressList.json';

const Bold = styled.span`
  font-weight: bold;
`;

const ButtonStyle = { backgroundColor: "#08A0F7", width: 104 };

async function getStakingList(accountAddress, contract) {
  const stakeLen = ~~(await contract.methods.getLengthOfUserStaking(accountAddress).call());
  return Array(stakeLen).fill(null).map(async (v, index) => {
    const staked = await contract.methods.staked(accountAddress, index).call();
    staked.id = index;
    staked.stakingTypeName = "stake";
    try {
      if (~~staked.packId === 0) {
        staked.rewardsAvailable = await contract.methods.getUserRewardFlex(accountAddress).call();
      } else {
        staked.rewardsAvailable = await contract.methods.getUserRewardLock(accountAddress, index).call();
      }
    } catch (e) {
      console.error(e);
    }

    return staked;
  });
}

/**
 * @param {string} accountAddress 
 * @param {ContractInstance} contract 
 * @param {number} lockPeriodDays Number of Days, can harvest after this period
 * @param {number} redemptionPeriodDays Number of Days
 * @param {"lock"|"privateLock"} stakingTypeName  vesting lock type
 * @returns {Promise<{}>}
 */
async function getStakingVestingLockedGeneric(accountAddress, contract, lockPeriodDays, redemptionPeriodDays, stakingTypeName, id) {

  const contractCalls = await Promise.all([
    contract.methods.addressToTokenLock(accountAddress).call(),
    contract.methods.getAvailableRedeemAmount(accountAddress).call()
  ]);

  const staked = contractCalls[0];
  const rewardsAvailable = contractCalls[1];
  const toDaySeconds = (new Date()).getTime() / 1000;
  /**
   * @description canHarvestAfter lockPeriodDays
   * @description units seconds 
   */
  const canHarvestAfter = lockPeriodDays * 24 * 60 * 60;

  /**
   * @description redemptionPeriodDays
   * @description units seconds 
   */
  const period = (redemptionPeriodDays * 24 * 60 * 60);

  staked.id = id;
  staked.stakingTypeName = stakingTypeName;
  staked.claimable = ~~staked.timestamp + canHarvestAfter < toDaySeconds;
  staked.unlockable = false;
  staked.rewardsAvailable = rewardsAvailable;

  staked.period = (period).toString();
  staked.rate = "0";
  staked.reward = staked.claimed;
  staked.packId = "-1";
  return staked;
}

/**
 * packs list
 * Pour récupérer la liste des staking packs à afficher, il faut dans un premier temps appeler la méthode du contrat getLengthOfStackingPackOptions()
 * Puis avec ce retour, itérer sur l'array stakingOptions() dans le contrat pour récupérer toutes les infos.
 * Un code de ce type fonctionne avec ethers, avec web3 la logique devrait être très similaire
 * const stakingOptionsLength = await myTvStaking.getLengthOfStackingPackOptions();
 * for (let i = 0; i < BigNumber.from(stakingOptionsLength).toNumber(); i++) {
 *    console.log(await myTvStaking.stakingOptions(i));
 *  }
 *     
 * Il y aura probablement un back-end qui va gérer ça, ou alors une petite mise à jour du contrat, 
 * mais pour le moment il faut appeler la méthode myTvStaking.staked(<user_address>, <stakeID>) 
 * du contrat et itérer dessus jusqu'à ce que le retour soit erroné (signifiant un out-of-range
 * dans le storage) 
 *     
 * Mais quand vous récupérez le status du staking de l'utilisateur avec 
 * const userStaking = await myTvStaking_.staked(<stakerAddress>, <stakeId>), 
 * vous avez les champs userStaking.reward et userStaking.claimed qui sont visibles et il faut que
 * la condition userStaking.claimed < userStaking.reward soit respecté pour que le contrat confirme 
 * la récupération des rewards
 */
async function setupMemo(
  accountAddress,
  stakingContract,
  vestingLockContract,
  vestingPrivateLockContract,
  setStakedList,
  isUserFromVesting,
) {
  if (!accountAddress || !stakingContract) return;
  const stakedList = await getStakingList(accountAddress, stakingContract);
  const lockList = isUserFromVesting ? [await getStakingVestingLockedGeneric(accountAddress, vestingLockContract, 30, 90 + 30, "lock", -1)] : [];
  const privateLockList = isUserFromVesting ? [await getStakingVestingLockedGeneric(accountAddress, vestingPrivateLockContract, 60, 200 + 60, "privateLock", -2)] : [];

  setStakedList(await Promise.all([...stakedList, ...lockList, ...privateLockList]));
}

/**
 * @description si unlockable ou que la periode + timestamp est => aujourd'hui alors true
 * @param {*} staked 
 * @returns {boolean}
 */
function isUnstakeable(staked) {
  if (staked.unlockable) return true;

  const period = new Date(~~staked.period * 1000);
  const createdAt = new Date(~~staked.timestamp * 1000);
  const unstakeAt = period.getTime() + createdAt.getTime();
  if (unstakeAt <= (new Date()).getTime()) {
    return true;
  }

  return false;
}

async function onHarvestClick(
  accountAddress,
  staked,
  stakingContract,
  vestingLockContract,
  vestingPrivateLockContract,
  setAreHarvestingLoading,
  setup
) {
  try {
    let claimRewards = null;
    setAreHarvestingLoading((o) => ({ ...o, [staked.id]: true }));
    switch (staked.stakingTypeName) {
      case "lock":
        console.log(staked);
        await vestingLockContract.methods.unlockToken().send({ from: accountAddress })
        break;

      case "privateLock":
        console.log(staked);
        await vestingPrivateLockContract.methods.unlockToken().send({ from: accountAddress })
        break;

      default:
        claimRewards = ~~staked.packId === 0 ? stakingContract.methods.claimRewardFlex(staked.id) : stakingContract.methods.claimRewardLock(staked.id);
        await claimRewards.send({ from: accountAddress });
        break;
    }
    await setup();
  } catch (err) {
    console.log(err);
  } finally {
    setAreHarvestingLoading((o) => ({ ...o, [staked.id]: false }));
  }
}

async function onUnStakeClick(activeAccount, staked, stakingContract, setAreStakingLoading, updateMyTvBalance, setup) {
  try {
    setAreStakingLoading((o) => ({ ...o, [staked.id]: true }));
    await stakingContract.methods.unstakeLock(staked.id).send({ from: activeAccount });
    await updateMyTvBalance();
    await setup();
  } catch (err) {
    console.log(err);
  } finally {
    setAreStakingLoading((o) => ({ ...o, [staked.id]: false }));
  }
}

const tableHead = [
  "MYTV staked",
  <span>Duration<br />(days)</span>,
  "Start date",
  "Redemption Date",
  "APR",
  "MYTV Rewards Available",
  <Bold>Operation</Bold>,
];

function getRowFromStaked(
  activeAccount,
  staked,
  stakingContract,
  vestingLockContract,
  vestingPrivateLockContract,
  setAreHarvestingLoading,
  areHarvestingLoading,
  setAreStakingLoading,
  areStakingLoading,
  updateMyTvBalance,
  setup
) {
  const timestamp = parseInt(`${staked.timestamp}000`, 10);
  const fieldsValue = {
    myTvStaked: ethers.utils.formatUnits(staked.amount, 4),
    durationDays: staked.period ? Math.floor(parseInt(staked.period, 10) / (60 * 60 * 24)) : "--",
    startDate: dayjs(timestamp).format(SIMPLE_DATE_FORMAT),
    redemptionDate: dayjs(timestamp).add(parseInt(staked.period, 10), "s").format(SIMPLE_DATE_FORMAT),
    annualizedInterest: `${ethers.utils.formatUnits(staked.reward ?? 0, 4)} -- ${staked.rate}%`,
    myTvRewardsAvailable: <Bold>{ethers.utils.formatUnits(staked.rewardsAvailable ?? 0, 4)}</Bold>,
    operation: (
      <Row key="cta" gutter={[10, 10]} justify="center" align="middle">
        <Col>
          <Button
            style={ButtonStyle}
            onClick={() => onHarvestClick(
              activeAccount,
              staked,
              stakingContract,
              vestingLockContract,
              vestingPrivateLockContract,
              setAreHarvestingLoading,
              setup
            )}
            loading={areHarvestingLoading[staked.id]}
            disabled={!staked.claimable} // parseFloat(staked.claimed) >= parseFloat(staked.reward)}
          >
            Harvest
          </Button>
        </Col>
        <Col>
          <Button
            style={ButtonStyle}
            onClick={() => onUnStakeClick(activeAccount, staked, stakingContract, setAreStakingLoading, updateMyTvBalance, setup)}
            loading={areStakingLoading[staked.id]}
            disabled={!isUnstakeable(staked)}
          >
            Unstake
          </Button>
        </Col>
      </Row>
    ),
  };
  return [
    fieldsValue.myTvStaked,
    fieldsValue.durationDays,
    fieldsValue.startDate,
    fieldsValue.redemptionDate,
    fieldsValue.annualizedInterest,
    fieldsValue.myTvRewardsAvailable,
    fieldsValue.operation,
  ];
}

function StakingsListTable({ active }) {
  const [areStakingLoading, setAreStakingLoading] = useState({});
  const [areHarvestingLoading, setAreHarvestingLoading] = useState({});
  const [stakedList, setStakedList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeAccount } = useUserStateContext();
  const { web3 } = useWeb3StateContext();
  const updateMyTvBalance = useUpdateMyTvBalance();
  const [isUserFromVesting, setIsUserFromVesting] = useState(false);

  const stakingContractRef = useRef(null);
  const vestingLockContractRef = useRef(null);
  const vestingPrivateLockContractRef = useRef(null);

  useMemo(() => {
    if (!web3) return null;
    stakingContractRef.current = stakingContractRef.current ?? makeStakingContract(web3);

    if (!isUserFromVesting) {
      return null;
    }

    vestingLockContractRef.current = vestingLockContractRef.current ?? makeVestingLockContract(web3);
    vestingPrivateLockContractRef.current = vestingPrivateLockContractRef.current ?? makeVestingPrivateLockContract(web3);

    return null;
  }, [web3, isUserFromVesting]);

  const setup = useMemo(() => {
    if (!activeAccount || !stakingContractRef.current) {
      setStakedList([]);
      setIsUserFromVesting(false);
      return null;
    };
    const isUserFromVestingLocal = VestingAddressList.indexOf(activeAccount) !== -1;
    setIsUserFromVesting(isUserFromVestingLocal);

    return () => setupMemo(
      activeAccount,
      stakingContractRef.current,
      vestingLockContractRef.current,
      vestingPrivateLockContractRef.current,
      setStakedList,
      isUserFromVestingLocal,
    );
  }, [activeAccount]);

  useEffect(() => {
    if (stakedList.length !== 0 || !active || !setup) return;
    (async () => {
      setLoading(true);
      await setup();
      setLoading(false);
    })();
  }, [active, setup, stakedList.length]);

  if (!active) return null;

  const stakedViewlist = stakedList.map((staked) => getRowFromStaked(
    activeAccount,
    staked,
    stakingContractRef.current,
    vestingLockContractRef.current,
    vestingPrivateLockContractRef.current,
    setAreHarvestingLoading,
    areHarvestingLoading,
    setAreStakingLoading,
    areStakingLoading,
    updateMyTvBalance,
    setup
  ));

  //Old [0.1, 0.08, 0.12, 0.12, 0.12, 0.12, 0.1, 0.24]
  return (
    <Table
      loading={loading}
      widths={[0.1, 0.09, 0.13, 0.14, 0.14, 0.16,  0.24]}
      rows={stakedViewlist}
      head={tableHead}
    />
  );
}

StakingsListTable.propTypes = { active: Proptypes.bool };

export default StakingsListTable;
