import React, { useContext, useState } from "react";
import Proptypes from "prop-types";
import styled, { ThemeContext } from "styled-components";
import { Row, Form } from "antd";

import Input from "../../lib/Input";
import Modal from "../../lib/Modal";
import Popover from "../../lib/Popover";
import MyTvButton from "../../lib/MyTvButton";
import inconsistentColors from "../../utils/helpers/inconsistentColors";
import { ReactComponent as InfoPopover } from "../../icons/info-popover.svg";
import { ReactComponent as InfoPopoverDark } from "../../icons/info-popover-dark.svg";
import { THEMES } from "../../utils/constants";
import { useUserStateContext } from "../providers/UserContextProvider";
import BasicButton from "../../lib/BasicButton";

const dividerColorFn = inconsistentColors(
  "rgba(97, 165, 250, 0.65)",
  "rgba(83, 97, 149, 0.65)"
);

const StyledInputGroup = styled.div`
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  /* color: ${({ theme }) => theme.fg1}; */
  color: ${({ theme }) => theme.textContentModal};
  opacity: 1;
  border-bottom: 1px solid ${dividerColorFn};
  margin-top: 24px;
  padding-bottom: 15px;
  width: 100%;
`;

const PopupFooter = styled.div`
  margin-top: 15px;
  width: 100%;
`;

const StyledAprContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 34px;
  margin-bottom: 47px;
  text-align: right;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledTitle = styled.div`
  font-size: 14px;
  text-align: left;
  font-weight: lighter;
  margin: 15px 0 8px 0;
  font-family: Circe;
`;
const StyledBalanceMytv = styled.div`
  width: 100%;
  display: block;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-top: 16px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 48px 48px !important;
  }
`;

const StyledPopoverContent = styled.div`
  text-align: left;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  opacity: 1;
  width: 271px;
  height: auto;
  padding: 24px;
  padding-bottom: 15px;
  table {
    width: 100%;
  }
`;

const StyledTimeCell = styled.td`
  height: 44px;
`;
const StyledRoiCell = styled.td`
  height: 44px;
  text-align: right;
`;

const StyledDivider = styled.div`
  border-top: 1px solid ${dividerColorFn};
  height: 0px;
`;

function PopoverContent() {
  return (
    <StyledPopoverContent>
      <table>
        <thead>
          <tr style={{ fontWeight: "bold", paddingBottom: "15px" }}>
            <td>Temps</td>
            <td style={{ textAlign: "right" }}>ROI</td>
          </tr>
        </thead>

        <StyledDivider style={{ width: "180%", marginTop: 10 }} />

        <tbody>
          <tr>
            <StyledTimeCell>Jour</StyledTimeCell>
            <StyledRoiCell>0,03%</StyledRoiCell>
          </tr>

          <tr>
            <StyledTimeCell>Semaine</StyledTimeCell>
            <StyledRoiCell>0,21%</StyledRoiCell>
          </tr>

          <tr>
            <StyledTimeCell>Mois</StyledTimeCell>
            <StyledRoiCell>0,92%</StyledRoiCell>
          </tr>

          <tr>
            <StyledTimeCell>An</StyledTimeCell>
            <StyledRoiCell>11%</StyledRoiCell>
          </tr>
        </tbody>
      </table>
    </StyledPopoverContent>
  );
}
export default function StartFarmingModal({
  width,
  title,
  open,
  onCancel,
  apr,
  onSuccess,
  loading,
  initialAllowance,
}) {
  const theme = useContext(ThemeContext);
  const [form] = Form.useForm();
  const { balanceTokens } = useUserStateContext();
  const [allowed, setAllowed] = useState(false);
  const [amount, setAmount] = useState(0);

  return (
    <StyledModal
      width={width}
      title={title}
      open={open}
      onCancel={onCancel}
      borderColor={dividerColorFn({ theme })}
    >
      <Form
        form={form}
        onFinish={() => {
          if (onSuccess) onSuccess({ amount: form.getFieldValue("amount"), onAllowed: () => { setAllowed(true) } });
        }}
        onChange={() => {
          try {
            let amountValue = form.getFieldValue("amount");
            amountValue = parseFloat(amountValue);
            setAmount(amountValue);
            setAllowed(amountValue <= initialAllowance);
          } catch (e) {
            console.log(e);
          }
        }}
      >
        <StyledInputGroup>
          <StyledTitle>Please input the amount</StyledTitle>
          <Form.Item
            name="amount"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.reject("required");
                  if (parseFloat(value) <= 0.0001)
                    return Promise.reject("0.001 minimal.");
                  if (parseFloat(value) > parseFloat(balanceTokens))
                    return Promise.reject("Insufficient amount");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="The amount"
              suffix={
                <BasicButton
                  $disableShadows
                  onClick={() => {
                    form.setFieldsValue({ amount: balanceTokens });
                  }}
                >
                  Max
                </BasicButton>
              }
              borderWidth={2}
              borderColor="#2E86FF"
              isNumber
            />
          </Form.Item>
          <StyledBalanceMytv>
            <div>
              Balance: {balanceTokens} LP <br />
            </div>
          </StyledBalanceMytv>
        </StyledInputGroup>
        <StyledAprContent>
          <Row
            style={{
              width: 65,
            }}
            justify="space-between"
          >
            APR
            <Popover triangleTop="40px" content={<PopoverContent />}>
              {theme.id === THEMES.LIGHT ? (
                <InfoPopover />
              ) : (
                <InfoPopoverDark />
              )}
            </Popover>
          </Row>
          <div>MYTV {apr} %</div>
        </StyledAprContent>
        <PopupFooter>
          <MyTvButton
            style={{ width: "70%", textTransform: "uppercase", margin: "auto" }}
            onClick={() => form.submit()}
            loading={loading}
          >
            {allowed || !amount ? "farm" : "approve"}
          </MyTvButton>
        </PopupFooter>
      </Form>
    </StyledModal>
  );
}

StartFarmingModal.propTypes = {
  open: Proptypes.bool,
  onCancel: Proptypes.func,
  width: Proptypes.number,
  title: Proptypes.string,
  apr: Proptypes.string,
  onSuccess: Proptypes.func,
  loading: Proptypes.bool,
  initialAllowance: Proptypes.number,
};
