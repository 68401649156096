import React, { useContext } from "react";
import Proptypes from "prop-types";
import styled, { ThemeContext } from "styled-components";
import Modal from "../../lib/Modal";
import MyTvButton from "../../lib/MyTvButton";
import inconsistentColors from "../../utils/helpers/inconsistentColors";

const dividerColorFn = inconsistentColors(
  "rgba(97, 165, 250, 0.65)",
  "rgba(83, 97, 149, 0.65)"
);

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 32px 48px;
  }
`;

const StyledDesc = styled.div`
  color: ${({ theme }) => theme.textContentModal};

  text-align: left;
  font: normal normal 300 14px/20px Circe;
  letter-spacing: 0px;
`;

export default function FarmingHarvestModal({
  open,
  onCancel,
  onClick,
  loading,
}) {
  const theme = useContext(ThemeContext);
  return (
    <StyledModal
      width={612}
      title="Harvest"
      open={open}
      onCancel={onCancel}
      borderColor={dividerColorFn({ theme })}
    >
      <StyledDesc>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
        rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
      </StyledDesc>

      <MyTvButton
        style={{
          width: "70%",
          textTransform: "uppercase",
          margin: "auto",
          marginTop: 22,
        }}
        onClick={onClick}
        loading={loading}
      >
        APPROVE
      </MyTvButton>
    </StyledModal>
  );
}

FarmingHarvestModal.propTypes = {
  open: Proptypes.bool,
  onCancel: Proptypes.func,
  onClick: Proptypes.func,
  loading: Proptypes.bool,
};
