import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import DashboardHeader from "../components/dashboard/DashboardHeader";
import CountdownDate from "../lib/CountdownDate";
import Page from "../lib/Page";
import PublicSaleForm from "../components/publicSale/PublicSaleForm";

import PublicSaleCondtions from "../components/publicSale/PublicSaleConditions";
import SaleOver from "../components/publicSale/SaleOver";
import {
  setBnbSent,
  setPublicSaleContext,
  setTokenPrice,
  usePublicSaleDispatchContext,
  usePublicSaleStateContext,
} from "../components/providers/PublicSaleContextProvider";
import {
  PUBLIC_SALE_CONTRACT_ADDRESS,
  PUBLIC_SALE_RESERVOIR_ADDRESS,
  PUBLIC_SALE_START_DATE,
  PUBLIC_SALE_PHASE_1_START_DATE,
  PUBLIC_SALE_PHASE_2_START_DATE,
  PUBLIC_SALE_END_DATE,
} from "../utils/constants";
import {
  updateBNBBalance,
  useUserDispatchContext,
  useUserStateContext,
} from "../components/providers/UserContextProvider";
import { makePublicSaleContract } from "../utils/helpers/makeContracts";
import { useWeb3StateContext } from "../components/providers/Web3ContextProvier";
import { ReactComponent as Logo } from "../icons/logo.svg";
// import { background } from "../../public/images/grid2.png";

dayjs.extend(customParseFormat);

const StyledPublicSalePage = styled(Page)`
  background-image: url("/images/dashboard-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom;
`;

const StyledDashboardBody = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const StyledSaleArea = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.bg2};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 90% 141px;
  color: ${({ theme }) => theme.fg1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 17px 32px;
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.fg1};
  margin: 10px 0;
  font: normal normal bold 42px/62px Circe;
  padding-bottom: 12px;
  border-bottom: 1px solid #545b7a;
`;

const StyledCondtionText = styled.div`
  border-left: 2px solid #2e86fb;
  padding-left: 16px;
  padding-top: 10px;
  font: normal normal 300 16px/16px Circe;
  color: ${({ theme }) => theme.fg4};
  align-items: start;
  margin: 0 20px 0 0;
  width: 100%;
  min-height: 202px;
`;
const StyledCondtionTextItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 12px;
  span:first-child {
    text-align: left;
  }
  span:last-child {
    text-align: right;
  }
`;

const StyledCondtionSubTitle = styled.div`
  color: ${({ theme }) => theme.fg4};
  align-items: start;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
`;

function PublicSale() {
  const isDefaultSellStarted = PUBLIC_SALE_START_DATE <= new Date();
  const [saleStarted, setSaleStarted] = useState(isDefaultSellStarted);
  const [sale1Ended, setSale1Ended] = useState(false);
  const [sale2Ended, setSale2Ended] = useState(false);
  const [form1Loading, setForm1Loading] = useState(false);
  const [form2Loading, setForm2Loading] = useState(false);
  const [firstCountertext, setFirstCountertext] = useState("Start In");
  const saleEnded = sale1Ended && sale2Ended;
  //
  // CHANGE COUNTER TEXT 'TO BE ANNOUCED'
  //
  const changeCounterAnnouced = false;
  const {
    investedPhaseOne,
    investedPhaseTwo,
    bnbSent,
    maxBnbPhaseOne,
    maxBnbPhaseTwo,
    maxBnbPerUserPhaseOne,
    maxBnbPerUserPhaseTwo,
    allowListPhaseOne,
    allowListPhaseTwo,
    tokenPrice,
  } = usePublicSaleStateContext();
  const { activeAccount, balanceBNB } = useUserStateContext();
  const { web3 } = useWeb3StateContext();
  const dispatchPublicSale = usePublicSaleDispatchContext();
  const userDispatch = useUserDispatchContext();

  useEffect(() => {
    if (!web3 || !activeAccount) return;
    const publicsaleContract = makePublicSaleContract(web3);

    publicsaleContract.methods
      .addressToUserWhitelist(activeAccount)
      .call()
      .then((r) => {
        dispatchPublicSale(
          setPublicSaleContext({
            allowListPhaseOne: r.allowListPhaseOne,
            allowListPhaseTwo: r.allowListPhaseTwo,
            investedPhaseOne: r.investedPhaseOne,
            investedPhaseTwo: r.investedPhaseTwo,
            unclaimedAmount: r.unclaimedAmount,
          })
        );
      })
      .catch(console.log);

    web3.eth
      .getBalance(activeAccount)
      .then((r) => {
        userDispatch(updateBNBBalance(r));
      })
      .catch(console.log);

    publicsaleContract.methods
      .BNBtoMYTVRatio()
      .call()
      .then((r) => {
        dispatchPublicSale(setTokenPrice(r));
      })
      .catch(console.log);
  }, [web3, dispatchPublicSale, activeAccount, userDispatch]);

  useEffect(() => {
    if (!web3) return;
    web3.eth.getBalance(PUBLIC_SALE_RESERVOIR_ADDRESS).then((r) => {
      dispatchPublicSale(setBnbSent(r));
    }).catch(console.log);
  }, [web3, dispatchPublicSale]);

  return (
    <StyledPublicSalePage header={<DashboardHeader />} requireLoggedIn={false}>
      {!saleStarted ? (
        <StyledDashboardBody>
          <StyledSaleArea
            style={{ backgroundImage: 'url("/images/grid3.png")' }}
          >
            <Row gutter={[42]}>
              <Col span={12}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <StyledTitle>
                      {" "}
                      <Logo
                        style={{ height: 33, width: "auto", marginRight: 24 }}
                      />
                      Public sale phase
                    </StyledTitle>{" "}
                  </Col>{" "}
                  <Col span={24}>
                    <PublicSaleCondtions
                      title="Phase 1 : 8th Feb.">
                      <StyledCondtionText>
                        <StyledCondtionSubTitle>
                          Pool information :
                        </StyledCondtionSubTitle>
                        <StyledCondtionTextItem>
                          {" "}
                          <span> Participants</span> <span>Few amount of winners (TBA)</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Min. Allocation</span> <span>0 BNB</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Max. Allocation</span> <span>TBA</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Hard Cap</span> <span>No hardcap</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Price</span> <span>0.12$</span>{" "}
                        </StyledCondtionTextItem>
                      </StyledCondtionText>
                      <StyledCondtionText>
                        <StyledCondtionSubTitle>
                          Token information :
                        </StyledCondtionSubTitle>
                        <StyledCondtionTextItem>
                          {" "}
                          <span> Name</span>{" "}
                          <span> MyTVchain - GUARANTEED</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Total supply</span> <span>390,000,000</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> ICO sale </span> <span>4,000,000</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Symbol</span> <span>MYTV</span>{" "}
                        </StyledCondtionTextItem>
                      </StyledCondtionText>{" "}
                    </PublicSaleCondtions>
                  </Col>
                </Row>
              </Col>{" "}
              <Col span={12}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <CountdownDate
                      dateTo={PUBLIC_SALE_START_DATE}
                      text={changeCounterAnnouced ? "Start In" : firstCountertext}
                      onEnd={() => {
                        setSaleStarted(true);
                        setFirstCountertext("End");
                      }} />
                  </Col>
                  <Col span={24}>
                    <PublicSaleCondtions title="Phase 2 : 9th Feb.">
                      <StyledCondtionText>
                        <StyledCondtionSubTitle>
                          Pool information :
                        </StyledCondtionSubTitle>
                        <StyledCondtionTextItem>
                          {" "}
                          <span> Participants</span> <span>NO LIMIT</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Min. Allocation</span> <span>0 BNB</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Max. Allocation</span> <span>TBA</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Hard Cap</span> <span>480.000$</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Price</span> <span>0.12$</span>{" "}
                        </StyledCondtionTextItem>
                      </StyledCondtionText>
                      <StyledCondtionText>
                        <StyledCondtionSubTitle>
                          Token information :
                        </StyledCondtionSubTitle>
                        <StyledCondtionTextItem>
                          {" "}
                          <span> Name</span> <span> MyTVchain - FCFS</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Total supply</span> <span>390,000,000</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> ICO sale </span> <span>4,000,000</span>{" "}
                        </StyledCondtionTextItem>

                        <StyledCondtionTextItem>
                          {" "}
                          <span> Symbol</span> <span>MYTV</span>{" "}
                        </StyledCondtionTextItem>
                      </StyledCondtionText>{" "}
                    </PublicSaleCondtions>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                Note :  Phase 1 participants can also participate in Phase 2
              </Col>
            </Row>
          </StyledSaleArea>
        </StyledDashboardBody>
      ) : null}
      {/* next step */}
      {saleStarted && !saleEnded ? (
        <StyledDashboardBody>
          <Row gutter={[32]}>
            <Col span={12}>
              <StyledSaleArea>
                <PublicSaleForm
                  onCounterEnd={() => {
                    setSale1Ended(true);
                  }}
                  startIn={PUBLIC_SALE_PHASE_1_START_DATE}
                  endIn={PUBLIC_SALE_PHASE_2_START_DATE}
                  title="Public sale 1"
                  maxBnb={maxBnbPhaseOne}
                  contractAddress={PUBLIC_SALE_CONTRACT_ADDRESS}
                  bnbSend={bnbSent}
                  tokenPrice={tokenPrice}
                  investedAmount={investedPhaseOne}
                  balance={balanceBNB}
                  whiteListed={allowListPhaseOne}
                  maxBnbPerUser={maxBnbPerUserPhaseOne}
                  loading={form1Loading}
                  onSuccess={async ({ amount, form }) => {
                    try {
                      setForm1Loading(true);
                      const publicsaleContract = makePublicSaleContract(web3);
                      const value = web3.utils.toWei(amount, "ether");
                      await publicsaleContract.methods.investInPhaseOne().send({
                        from: activeAccount,
                        value,
                      });

                      const bal = await web3.eth.getBalance(activeAccount);
                      userDispatch(updateBNBBalance(bal));

                      dispatchPublicSale(
                        setBnbSent(
                          (
                            parseInt(bnbSent, 10) + parseInt(value, 10)
                          ).toString()
                        )
                      );

                      publicsaleContract.methods
                        .addressToUserWhitelist(activeAccount)
                        .call()
                        .then((r) => {
                          dispatchPublicSale(
                            setPublicSaleContext({
                              investedPhaseOne: r.investedPhaseOne,
                              investedPhaseTwo: r.investedPhaseTwo,
                              unclaimedAmount: r.unclaimedAmount,
                            })
                          );
                        })
                        .catch(console.log);

                      form.resetFields();
                    } catch (e) {
                      console.log(e);
                    } finally {
                      setForm1Loading(false);
                    }
                  }}
                />
              </StyledSaleArea>
            </Col>
            <Col span={12}>
              <StyledSaleArea>
                <PublicSaleForm
                  onCounterEnd={() => {
                    setSale2Ended(true);
                  }}
                  startIn={PUBLIC_SALE_PHASE_2_START_DATE}
                  endIn={PUBLIC_SALE_END_DATE}
                  title="Public sale 2"
                  maxBnb={maxBnbPhaseTwo}
                  contractAddress={PUBLIC_SALE_CONTRACT_ADDRESS}
                  bnbSend={bnbSent}
                  tokenPrice={tokenPrice}
                  investedAmount={investedPhaseTwo}
                  balance={balanceBNB}
                  whiteListed={allowListPhaseTwo}
                  loading={form2Loading}
                  maxBnbPerUser={maxBnbPerUserPhaseTwo}
                  onSuccess={async ({ amount, form }) => {
                    try {
                      setForm2Loading(true);
                      const publicsaleContract = makePublicSaleContract(web3);
                      const value = web3.utils.toWei(amount, "ether");
                      await publicsaleContract.methods.investInPhaseTwo().send({
                        from: activeAccount,
                        value,
                      });

                      const bal = await web3.eth.getBalance(activeAccount);
                      userDispatch(updateBNBBalance(bal));

                      dispatchPublicSale(setBnbSent((parseInt(bnbSent, 10) + parseInt(value, 10)).toString()));
                      publicsaleContract.methods
                        .addressToUserWhitelist(activeAccount)
                        .call()
                        .then((r) => {
                          dispatchPublicSale(
                            setPublicSaleContext({
                              investedPhaseOne: r.investedPhaseOne,
                              investedPhaseTwo: r.investedPhaseTwo,
                              unclaimedAmount: r.unclaimedAmount,
                            })
                          );
                        })
                        .catch(console.log);
                      form.resetFields();
                    } catch (e) {
                      console.log(e);
                    } finally {
                      setForm2Loading(false);
                    }
                  }}
                />
              </StyledSaleArea>
            </Col>
          </Row>
        </StyledDashboardBody>
      ) : null}
      {/* final step */}
      {saleEnded ? (
        <StyledDashboardBody>
          <SaleOver />
        </StyledDashboardBody>
      ) : null}{" "}
    </StyledPublicSalePage>
  );
}

export default PublicSale;
