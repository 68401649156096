import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ethers } from "ethers";
import DashboardKPICard from "../../lib/DashboardKPICard";
import MyTvButton from "../../lib/MyTvButton";
import SaleOverDetails from "./SaleOverDetails";
import { ReactComponent as FaqIcon } from "../../icons/faq.svg";
import { useUserStateContext } from "../providers/UserContextProvider";
import { usePublicSaleStateContext } from "../providers/PublicSaleContextProvider";
import { makePublicSaleContract } from "../../utils/helpers/makeContracts";
import formatUnits from "../../utils/helpers/formatUnits";
import { useWeb3StateContext } from "../providers/Web3ContextProvier";

const StyledSaleOverWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 500px);
  grid-template-rows: auto auto auto;
  align-items: stretch;
  gap: 20px;
  grid-auto-flow: row dense;
`;

const StyledSaleOverTotalWrapper = styled.div`
  width: 100%;
  padding: 30px;
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  min-height: 242px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledSaleOverTotalTitle = styled.div`
  font: normal normal bold 26px/39px Circe;
  color: #2e86fa;
`;

const StyledHelpWrapper = styled.div`
  width: 100%;
  padding: 30px;
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  min-height: 242px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledHelpText = styled.div`
  font: normal normal 300 16px/23px Circe;
  text-align: center;
  color: ${({ theme }) => theme.fg1};
  margin-top: 16px;
  width: 70%;
`;

function SaleOver() {
  const { activeAccount } = useUserStateContext();
  const { web3 } = useWeb3StateContext();
  const { bnbSent, maxBnbPhaseOne, maxBnbPhaseTwo, tokenPrice } = usePublicSaleStateContext();
  const formatedMaxBnbPhaseOne = useMemo(
    () => (maxBnbPhaseOne && web3 ? web3.utils.fromWei(maxBnbPhaseOne) : ""),
    [maxBnbPhaseOne, web3]
  );

  const formatedMaxBnbPhaseTwo = useMemo(
    () => (maxBnbPhaseTwo && web3 ? web3.utils.fromWei(maxBnbPhaseTwo) : ""),
    [maxBnbPhaseTwo, web3]
  );

  const [loading, setIsLoading] = useState(false);

  const formatedBnbSent = useMemo(
    () => (bnbSent && web3 ? web3.utils.fromWei(bnbSent) : ""),
    [bnbSent, web3]
  );

  const [boughtMytv, setBoughtMytv] = useState(0);
  useEffect(() => {
    if (!web3 || !activeAccount || !tokenPrice) return;
    (async () => {
      const publicsaleContract = makePublicSaleContract(web3);

      /*
        On peut partir sur ce code pour calculer la quantité de MYTV acheté:
        
        const bal = await myTvPublicSale.addressToUserWhitelist("0x9047Df71DBf59f8ECa192ab99c54295bf9489c31");
        const investedAmount = BigNumber.from(bal.investedPhaseOne).add(bal.investedPhaseTwo);
        const investedAmountFormatted = Number(ethers.utils.formatEther(investedAmount));
        const ratio = await myTvPublicSale.BNBtoMYTVRatio();
        const ratioFormatted = Number(ethers.utils.formatUnits(ratio, 4));
        const totalMyTvBought = ratioFormatted * investedAmountFormatted;
        console.log(totalMyTvBought);

        Il faudrait garder que 4 décimales après la virgule effectivement
      */

      const bal = await publicsaleContract.methods
        .addressToUserWhitelist(activeAccount)
        .call();

      const investedAmount = ethers.BigNumber.from(bal.investedPhaseOne).add(
        bal.investedPhaseTwo
      );

      const investedAmountFormatted = Number(
        ethers.utils.formatEther(investedAmount)
      );
      const ratioFormatted = Number(ethers.utils.formatUnits(tokenPrice, 4));
      const totalMyTvBought = parseFloat(
        (ratioFormatted * investedAmountFormatted).toFixed(4)
      );

      setBoughtMytv(totalMyTvBought);
    })();
  }, [web3, activeAccount, tokenPrice]);

  const [canClaim, setCanClaim] = useState(false);

  useEffect(() => {
    if (!web3) return;
    (async () => {
      const publicsaleContract = makePublicSaleContract(web3);

      if (!activeAccount) {
        setCanClaim(false);
        return;
      };

      const { unclaimedAmount } = await publicsaleContract.methods.addressToUserWhitelist(activeAccount).call();
      if (unclaimedAmount === "0") {
        setCanClaim(false);
        return;
      }

      setCanClaim((await publicsaleContract.methods.claimEnabled().call()));
    })();
  }, [web3, activeAccount]);

  const formattedTokenPrice = useMemo(() => {
    if ([undefined, null, ""].indexOf(tokenPrice) !== -1) return null;
    return formatUnits(tokenPrice, 4);
  }, [tokenPrice]);

  return (
    <StyledSaleOverWrapper>
      <SaleOverDetails
        maxBnbPhaseOne={formatedMaxBnbPhaseOne}
        maxBnbPhaseTwo={formatedMaxBnbPhaseTwo}
        bnbSend={formatedBnbSent}
        tokenPrice={formattedTokenPrice}
        style={{ gridRow: "1 / 3" }}
      />

      <StyledSaleOverTotalWrapper>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <StyledSaleOverTotalTitle style={{ marginRight: "25px" }}>
            Your total MYTV bought
          </StyledSaleOverTotalTitle>
          <DashboardKPICard firstAmount={boughtMytv} />
        </div>
        <MyTvButton
          disabled={!canClaim}
          onClick={() => {
            if (!web3 || !activeAccount) return;
            setIsLoading(true);
            const publicsaleContract = makePublicSaleContract(web3);

            publicsaleContract.methods
              .claimUserTokens()
              .send({ from: activeAccount })
              .then(() => {
                setCanClaim(false);
              })
              .catch(console.log)
              .finally(() => {
                setIsLoading(false);
              });
          }}
          style={{ width: "100%", marginTop: "30px" }}
          loading={loading}
        >
          CLAIM
        </MyTvButton>
      </StyledSaleOverTotalWrapper>

      <StyledHelpWrapper>
        <FaqIcon fill="#339DFF" style={{ width: 29, marginBottom: 12 }} />{" "}
        <StyledSaleOverTotalTitle>
          Vous avez raté la public sale ?{" "}
        </StyledSaleOverTotalTitle>
        <StyledHelpText />
        <MyTvButton
          onClick={() => {
            window.open("https://pancakeswap.finance/swap", "blank");
          }}
          style={{ width: "100%", marginTop: "30px" }}
        >
          GO TO SWAP DASHBOARD
        </MyTvButton>
      </StyledHelpWrapper>
    </StyledSaleOverWrapper>
  );
}

export default SaleOver;
