import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import { Col, Row } from "antd";

import Popover from "./Popover";
import MyTvButton from "./MyTvButton";
import Arrow from "../icons/rightArrow.svg";
import { ReactComponent as InfoPopover } from "../icons/info-popover.svg";
import { ReactComponent as InfoPopoverDark } from "../icons/info-popover-dark.svg";
import { THEMES } from "../utils/constants";

const StyledFarmingCardCard = styled.div`
  width: 361px;
  min-height: 350px;
  background: transparent
    linear-gradient(
      324deg,
      ${({ theme }) => theme.sfcBg1} 0%,
      ${({ theme }) => theme.sfcBg2} 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 32px;
  font-family: Circe;
`;

const StyledFarmingCardCardHeader = styled.div`
  align-items: center;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 32px;
`;

const StyledFarmingCardContent = styled.div`
  width: 80%;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledFarmingCardTitle = styled.div`
  width: 100%;
  text-align: left;
  font: normal normal bold 30px/45px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledMultiplyHelp = styled.span`
  font-size: 16px;
  text-align: left;
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  margin-right: 7px;
`;

const StyledContentWrapper = styled.div`
  list-style: none;
  margin-left: 20px;
  padding-left: 24px;
  border-left: 3px solid #71c0eb;
  font: normal normal bold 18px/26px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 1;
  margin-top: 10px;
`;

const ContentItem = styled.li`
  font: normal normal 18px/26px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 1;
  margin-bottom: 16px;
`;

const StyledTvlWrapper = styled(Row)`
  background-color: ${({ theme }) => theme.sfcTvlWrapper};
  border-radius: 10px;
  color: ${({ theme }) => theme.fg1};
`;
const StyledMultiplyValue = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const StyledTitleCardFarming = styled.h5`
  margin-bottom: 0;
  color: ${({ theme }) => theme.fg1};
  font: normal normal bold 30px/45px Circe;
`;

const StyledPopovercontent = styled.div`
  text-align: left;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  width: 320px;
  height: auto;
  padding: 24px;
`;

export default function FarmingCard({
  title,
  apy,
  apr,
  rewards,
  tvl,
  textButton,
  onBtnClick,
  icon1,
  icon2,
  multiple,
  loading,
  style,
  className,
}) {
  const theme = useContext(ThemeContext);

  return (
    <StyledFarmingCardCard style={style} className={className}>
      <StyledFarmingCardCardHeader>
        <div style={{ marginBottom: 16, width: "100%" }}>
          <img
            src={icon1}
            alt=""
            style={{
              width: 55,
              objectFit: "cover",
              opacity: 1,
            }}
          />
          <img
            src={icon2}
            alt=""
            style={{
              width: 55,
              objectFit: "cover",
              opacity: 1,
              marginLeft: -15,
            }}
          />
        </div>
        <Row style={{ padding: 0, width: "100%" }}>
          <Col style={{ width: "100%" }}>
            <StyledFarmingCardTitle>
              <StyledTitleCardFarming>{title}</StyledTitleCardFarming>
              <StyledMultiplyValue>
                <StyledMultiplyHelp>x{multiple}</StyledMultiplyHelp>

                <Popover
                  triangleTop="40px"
                  content={
                    <StyledPopovercontent>
                      The Multiplier represents the proportion of MYTV rewards
                      each farm receives, as a proportion of the MYTV produced
                      each block. For example, if a 1x farm received 1 CAKE per
                      block, a 40x farm would receive 40 CAKE per block. This
                      amount is already included in all APR calculations for the
                      farm
                    </StyledPopovercontent>
                  }
                  style={{ lineHeight: "10px" }}
                >
                  {theme.id === THEMES.LIGHT ? (
                    <InfoPopover />
                  ) : (
                    <InfoPopoverDark />
                  )}
                </Popover>
              </StyledMultiplyValue>
            </StyledFarmingCardTitle>
          </Col>
        </Row>
      </StyledFarmingCardCardHeader>

      {/* Content */}
      <StyledFarmingCardContent>
        <Row style={{ marginBottom: "5px", width: "100%" }}>
          <Col style={{ width: "100%" }}>
            <StyledContentWrapper>
              <ContentItem>
                <b>APY: {apy}</b>
              </ContentItem>
              <ContentItem>
                <b>APR: {apr}</b>
              </ContentItem>
              <ContentItem
                style={{ font: "normal normal 300 18px/26px Circe" }}
              >
                Rewards: {rewards} MYTV/block
              </ContentItem>
            </StyledContentWrapper>
          </Col>
        </Row>
        <StyledTvlWrapper
          style={{ padding: "16px", marginBottom: "5px", width: "100%" }}
        >
          <Col>TVL:</Col>
          <Col style={{ marginLeft: "auto" }}>{tvl}</Col>
        </StyledTvlWrapper>
        <Row style={{ marginBottom: "5px", marginTop: "25px" }}>
          <Col>
            <MyTvButton
              style={{ width: "225px" }}
              iconUrl={Arrow}
              onClick={onBtnClick}
              loading={loading}
            >
              {textButton}
            </MyTvButton>
          </Col>
        </Row>
      </StyledFarmingCardContent>
    </StyledFarmingCardCard>
  );
}
FarmingCard.propTypes = {
  title: PropTypes.string,
  apy: PropTypes.string,
  apr: PropTypes.string,
  rewards: PropTypes.string,
  tvl: PropTypes.string,
  textButton: PropTypes.string,
  onBtnClick: PropTypes.func,
  icon1: PropTypes.string,
  icon2: PropTypes.string,
  multiple: PropTypes.number,
  loading: PropTypes.bool,
  style: PropTypes.any,
  className: PropTypes.string,
};
