import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Row } from "antd";
import { Link } from "react-router-dom";

import DashboardCard from "../../lib/DashboardCard";
import { ReactComponent as RightArrow } from "../../icons/rightArrow.svg";
import { ReactComponent as FaqIcon } from "../../icons/faq.svg";
import { APP_LINKS } from "../../utils/constants";

const StyledFaqBody = styled.div`
  text-align: center;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

function FaqCard() {
  const activeTheme = useContext(ThemeContext);
  return (
    <DashboardCard
      style={{ padding: "25px 32px 16px 32px", height: 190 }}
      title={
        <>
          <FaqIcon
            fill={activeTheme.icon3}
            style={{ width: 29, marginBottom: 8 }}
          />
          FAQ
        </>
      }
      titleStyle={{ paddingBottom: 0 }}
    >
      <StyledFaqBody>
        <div>Have a question ? We can help you find your answers here</div>

        <Row justify="end">
          <Link to={APP_LINKS.FAQ} style={{display: "none"}}>
            <RightArrow stroke={activeTheme.icon3} />
          </Link>
        </Row>
      </StyledFaqBody>
    </DashboardCard>
  );
}

export default FaqCard;
