import { BigNumber, ethers } from "ethers";
import { MYTV, MyTvContracts } from "../lib/MytvContracts";
import {
  GOVERNANCE_CONTRACT_ADDRESS,
  PANCAKESWAP_API,
  INITIAL_SUPPLY,
  POOL_FARMING_BLOCKS_PER_YEAR,
  PANCAKE_POOL_ADDRESS,
} from "./constants";

let bnbTokenPriceStatic = null;
async function getBnbTokenPrice() {
  if (bnbTokenPriceStatic) return bnbTokenPriceStatic;
  /**
   * @TODO GOVERNANCE_CONTRACT_ADDRESS utiliser cette constante en prod et en dev
   */
  try {
    const uri = `${PANCAKESWAP_API}0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82`;
    const response = await fetch(uri);
    const content = await response.json();
    bnbTokenPriceStatic = Number(content.data.price_BNB);
    return bnbTokenPriceStatic;
  } catch (err) {
    console.error(err);
  }
  return -1;
}

let tokenPriceStatic = null;
async function getTokenPrice() {
  if (tokenPriceStatic) return tokenPriceStatic;
  /**
   * @TODO GOVERNANCE_CONTRACT_ADDRESS utiliser cette constante en prod et en dev
   */
  try {
    const uri = `${PANCAKESWAP_API}0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82`;
    const response = await fetch(uri);
    const content = await response.json();
    tokenPriceStatic = Number(content.data.price);
    return tokenPriceStatic;
  } catch (err) {
    console.error(err);
  }
  return -1;
}

/**
 * @TODO Le smart contract n'existe pas pour le moment, cette fonction est desactivé
 * @TODO Penser a reactiver pour la prod
 */
async function getStakingTokenPrice() {
  const myTvContracts = new MyTvContracts();
  const balancePancakePoolBnbAsync = myTvContracts.provider.getBalance(PANCAKE_POOL_ADDRESS);
  const balancePancakePoolMtvAsync = myTvContracts.governance.balanceOf(PANCAKE_POOL_ADDRESS);
  const bnbTokenPriceAsync = getBnbTokenPrice();
  const totalSupplyAsync = myTvContracts.poolFarming.totalSupply();

  const [
    balancePancakePoolBnb,
    balancePancakePoolMtv,
    bnbTokenPrice,
    totalSupply
  ] = await Promise.all([
    balancePancakePoolBnbAsync,
    balancePancakePoolMtvAsync,
    bnbTokenPriceAsync,
    totalSupplyAsync
  ])

  const amountInBnb = balancePancakePoolMtv.mul(ethers.utils.parseEther(bnbTokenPrice.toString()));
  const ttValueInBnb = amountInBnb.add(balancePancakePoolBnb);
  return ttValueInBnb.div(totalSupply);
}

function getFarmApr(
  stakingTokenPrice,
  rewardTokenPrice,
  totalStaked,
  tokenPerBlock,
) {
  const totalRewardPricePerYear = ethers.utils.parseEther(rewardTokenPrice.toString()).mul(tokenPerBlock).mul(POOL_FARMING_BLOCKS_PER_YEAR);
  const totalStakingTokenInPool = BigNumber.from(stakingTokenPrice).mul(totalStaked);
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).mul(100);
  try {
    return apr.toNumber();
  } catch (e) {
    console.error(e);
    return null;
  }
}

function getFarmApy(farmApr) {
  return ((1 + (farmApr / 365)) ** 365) - 1;
}

export const fetchMyTvKpi = async () => {
  const myTvContracts = new MyTvContracts();

  const stakingTokenPriceAsync = getStakingTokenPrice();
  const tokenPriceAsync = getTokenPrice();
  const farmingBalanceAsync = myTvContracts.getFarmingBalance();
  const tokenPerBlockAsync = myTvContracts.getTokenPerBlock();
  const [
    tokenPrice,
    staked,
    pancakeSwapTokens,
    totalSupply,
    stakingTokenPrice,
    farmingBalance,
    tokenPerBlock
  ] = await Promise.all([
    tokenPriceAsync,
    myTvContracts.totalStake(),
    myTvContracts.totalPancakeSwapTokens(),
    myTvContracts.totalSupply(),
    stakingTokenPriceAsync,
    farmingBalanceAsync,
    tokenPerBlockAsync,
  ]);

  const supplyStaked = Number(staked) + Number(pancakeSwapTokens);
  const supplyStakedPercent = (supplyStaked * 100) / Number(INITIAL_SUPPLY);

  const marketCap = tokenPrice ? parseFloat(ethers.utils.formatUnits(totalSupply, MYTV)) * tokenPrice : null;
  const supplyStakedPrc = supplyStakedPercent;

  let farmApr = "N/A";
  try {
    farmApr = getFarmApr(stakingTokenPrice, await getBnbTokenPrice(), farmingBalance, tokenPerBlock)
  } catch (e) {
    console.error(e);
  }
  const tvl = tokenPrice ? supplyStaked * tokenPrice : null;
  return {
    tokenPrice,
    totalSupply,
    contractAddress: GOVERNANCE_CONTRACT_ADDRESS,
    marketCap,
    supplyStaked: supplyStakedPrc,
    tvl,
    farmingApr: farmApr.toFixed(0),
    farmingApy: getFarmApy(farmApr).toFixed(0),
  };
};

export const fetchMyTvPacks = async () => {
  return (new MyTvContracts()).getStakingPacks();
};

export const fetchUserInfos = async (wallet) => {
  const myTvContracts = new MyTvContracts();

  const [
    latest,
    userBalance,
    farmingR,
    stakingR,
    farming,
    staking
  ] = await Promise.all([
    getTokenPrice(),
    myTvContracts.balanceOf(wallet),
    myTvContracts.totalUserPendingFarmingReawards(wallet),
    myTvContracts.totalUserStakingReawards(wallet),
    myTvContracts.totalUserFarming(wallet),
    myTvContracts.totalUserStakings(wallet),
  ]);
  const totalTokensRewards = Number(farmingR) + Number(stakingR);


  const totalRewards = latest ? (~~(totalTokensRewards * latest * 100) / 100) : 'N/A'
  const balance = latest ? (~~(Number(userBalance) * latest * 100) / 100) : 'N/A'
  const totalTvl = latest ? (~~((Number(farming) + Number(staking)) * latest * 100) / 100) : 'N/A'

  return {
    tokenPrice: latest,
    balanceTokens: userBalance,
    totalTokensRewards,
    totalRewards,
    balance,
    totalTvl,
  };
};
