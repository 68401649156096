import React, { useContext, useReducer } from "react";
import Proptypes from "prop-types";

const initialState = {
  totalSupply: null,
  contractAddress: null,
  marketCap: null,
  supplyStaked: null,
  tvl: null,
  farmingApr: null,
  farmingApy: null,
  packs: [],
};

export const MyTvStateContext = React.createContext(initialState);
export const MyTvDispatchContext = React.createContext(() => { });

export const useMyTvStateContext = () => useContext(MyTvStateContext);
export const useMyTvDispatchContext = () => useContext(MyTvDispatchContext);

const ACTION_RESET = "mytv-reset";
const ACTION_SET = "mytv-set";
const ACTION_SET_PACKS = "mytv-set-packs";

export const resetMyTvContext = () => ({ type: ACTION_RESET });
export const setMyTvContext = (payload) => ({ type: ACTION_SET, payload });
export const setMyTvPacks = (payload) => ({ type: ACTION_SET_PACKS, payload });

function reducer(state, action) {
  switch (action.type) {
    case ACTION_SET:
      return { ...state, ...action.payload };

    case ACTION_SET_PACKS:
      return { ...state, packs: action.payload };

    case ACTION_RESET:
      return initialState;
    default:
      console.log(action);
      throw new Error("Bad Action Type");
  }
}

function MyTvContextProvier({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MyTvDispatchContext.Provider value={dispatch}>
      <MyTvStateContext.Provider value={state}>
        {children}
      </MyTvStateContext.Provider>
    </MyTvDispatchContext.Provider>
  );
}
MyTvContextProvier.propTypes = { children: Proptypes.any };

export default MyTvContextProvier;
