import React, { useMemo, useState } from "react";
import { Form, Row } from "antd";
import styled from "styled-components";
import Proptypes from "prop-types";
import * as ethers from "ethers";
import dayjs from "dayjs";
import Input from "../../lib/Input";
import Modal from "../../lib/Modal";
import MyTvButton from "../../lib/MyTvButton";
import { useUserStateContext } from "../providers/UserContextProvider";
import BasicButton from "../../lib/BasicButton";

const StyledForm = styled(Form)`
  width: 100%;
  .ant-checkbox-wrapper {
    font: normal normal 300 18px/26px Circe;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #61a5faa6;
  margin: 8px 0;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ $padding }) => $padding};
`;

const ColoredBorderBox = styled.div`
  border-left: 2px solid #71c0eb;
  min-height: 50px;
  padding-left: 15px;
  margin-left: 7.4px;
  margin-right: 17px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
`;

const StyledFieldTitle = styled.div`
  font: normal normal 300 14px/20px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  margin-bottom: 8px;
`;

const StyledSubtitle = styled.div`
  text-align: right;
  font: normal normal 300 14px/20px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledTitle = styled.div`
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledDetailTxt = styled(Row)`
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 0.47;
  display: flex;
  align-items: center;
`;

const StyledDetailInfo = styled.div`
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textContentModal};
`;

const StyledBold = styled.div`
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 32px 48px !important;

    height: 707px;
    height: auto !important;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  .ant-form-item {
    /* ant-form-item-explain-error */
  }
`;


export default function StakeModal({
  open,
  onCancel,
  title,
  apyPeriod,
  apy,
  depositFee,
  withdrawFee,
  lockPeriodDays,
  loading,
  onSuccess,
}) {
  const [form] = StyledForm.useForm();
  const { balanceTokens, activeAccount } = useUserStateContext();
  const stakeDate = useMemo(() => dayjs(), []);
  const redemptionDate = useMemo(
    () => stakeDate.add(lockPeriodDays, "d"),
    [stakeDate, lockPeriodDays]
  );
  const DISPLAY_DATE_FORMAT = "YYYY-MM-DD HH:mm";
  const [estimatedInterests, setEstimatedInterests] = useState(-1);

  const estimate = (amount) => {
    setEstimatedInterests(
      ethers.utils.formatUnits(
        Math.floor((amount * lockPeriodDays * apy) / (100 * 365)).toString(),
        4
      )
    );
  };

  return (
    <StyledModal
      headerStyle={{ marginBottom: 12 }}
      width={612}
      title={title}
      open={open}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        name="dynamic_rule"
        size="large"
        onFinish={() => {
          if (onSuccess) onSuccess({ amount: form.getFieldValue("amount") });
        }}
        onChange={() => {
          try {
            const amount = ethers.utils
              .parseUnits(form.getFieldValue("amount"), 4)
              .toNumber();

            estimate(amount);
          } catch (e) {
            setEstimatedInterests(-1);
          }
        }}
      >
        <StyledFieldTitle>Please input the amount</StyledFieldTitle>

        <StyledFormItem
          name="amount"
          rules={[
            {
              validator(_, value) {
                if (!value)
                  return Promise.reject("Please input the amount");
                if (parseFloat(value) <= 1) {
                  return Promise.reject("Must be greater than 1");
                }

                if (parseFloat(value) > parseFloat(balanceTokens)) {
                  return Promise.reject("Insufficient amount");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="The amount"
            suffix={
              <BasicButton
                $disableShadows
                onClick={() => {
                  form.setFieldsValue({ amount: balanceTokens });
                  estimate(ethers.utils.parseUnits(balanceTokens, 4).toNumber());
                }}
              >
                Max
              </BasicButton>
            }
            isNumber
            borderWidth={2}
            borderColor="#2e86ff"
          />
        </StyledFormItem>
        <Row justify="space-between" align="middle" style={{ marginTop: 8 }}>
          <StyledSubtitle/>
          <StyledSubtitle>Balance {balanceTokens} MYTV</StyledSubtitle>
        </Row>
        <Divider />
        <StyledInfoWrapper $padding="0 0 16px 0" style={{ marginTop: 24 }}>
          <StyledTitle>Stake Date</StyledTitle>
          <StyledTitle>{stakeDate.format(DISPLAY_DATE_FORMAT)}</StyledTitle>
        </StyledInfoWrapper>

        <ColoredBorderBox>
          <Row justify="space-between" align="middle" style={{ flex: 1 }}>
            <StyledDetailTxt>Interest Period</StyledDetailTxt>

            <StyledDetailInfo>{lockPeriodDays} days</StyledDetailInfo>
          </Row>
        </ColoredBorderBox>

        <StyledInfoWrapper $padding="15px 0 16px 0">
          <StyledTitle>Redemption Date</StyledTitle>
          <StyledTitle>
            {redemptionDate.format(DISPLAY_DATE_FORMAT)}
          </StyledTitle>
        </StyledInfoWrapper>

        <StyledInfoWrapper $padding="0 0 16px 0">
          <StyledTitle>{apyPeriod}</StyledTitle>
          <StyledTitle>MYTV {apy?.toFixed(2)}%</StyledTitle>
        </StyledInfoWrapper>

        <ColoredBorderBox style={{ marginBottom: "16px" }}>
          <Row
            align="middle"
            justify="space-between"
            style={{ padding: "8px 0" }}
            wrap={false}
          >
            <Row
              justify="space-between"
              align="middle"
              style={{ width: 140, lineHeight: "5px" }}
              wrap={false}
            >
              <StyledDetailTxt>Deposit fee</StyledDetailTxt>
            </Row>
            <StyledDetailInfo>{depositFee}</StyledDetailInfo>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            style={{ padding: "8px 0" }}
            wrap={false}
          >
            <Row
              justify="space-between"
              align="middle"
              style={{ width: 140 }}
              wrap={false}
            >
              <StyledDetailTxt>Withdrawal fee</StyledDetailTxt>
            </Row>

            <StyledDetailInfo>{withdrawFee}</StyledDetailInfo>
          </Row>
        </ColoredBorderBox>
        <Divider />

        <Row
          justify="space-between"
          align="middle"
          style={{ margin: "16px 0" }}
        >
          <StyledBold>Estimated Interests</StyledBold>

          <StyledBold>
            {estimatedInterests > -1 ? estimatedInterests : ""}
            {estimatedInterests > -1 ? " MYTV" : ""}
          </StyledBold>
        </Row>
        <Divider />
        <MyTvButton
          style={{ width: "70%", margin: "14px auto 0" }}
          onClick={() => {
            form.submit();
          }}
          loading={loading}
          disabled={!activeAccount}
        >
          STAKE
        </MyTvButton>
      </StyledForm>
    </StyledModal>
  );
}

StakeModal.propTypes = {
  open: Proptypes.bool,
  onCancel: Proptypes.func,
  title: Proptypes.string,
  apyPeriod: Proptypes.any,
  apy: Proptypes.any,
  depositFee: Proptypes.any,
  withdrawFee: Proptypes.any,
  lockPeriodDays: Proptypes.number,
  loading: Proptypes.bool,
  onSuccess: Proptypes.func,
};
