import { ethers } from 'ethers';

import MyTvGovernanceABI from '../utils/contracts/MyTvGovernance_ABI.json';
import MyTvStakingABI from '../utils/contracts/MyTvStaking_ABI.json';
import MyTvFarmingABI from '../utils/contracts/MyTvFarming_ABI.json';
import MyTvPublicSaleABI from '../utils/contracts/MyTvPublicSale_ABI.json';
import ERC20_ABI from '../utils/contracts/ERC20_ABI.json';

import {
  FARMING_CONTRACT_ADDRESS,
  GOVERNANCE_CONTRACT_ADDRESS,
  POOL_FARMING_CONTRACT_ADDRESS,
  PUBLIC_SALE_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
  JSON_RPC_PROVIDER_URI,
  MY_TV_PANCAKESWAP_ADDRESS,
} from "../utils/constants";

export const MYTV = 4;
export const DAY_IN_S = 24 * 60 * 60;

let MyTvContractsInstance = null;

export class MyTvContracts {
  constructor() {
    if (MyTvContractsInstance) {
      return MyTvContractsInstance;
    }
    MyTvContractsInstance = this;

    const provider = new ethers.providers.JsonRpcProvider(
      JSON_RPC_PROVIDER_URI,
    );

    this.provider = provider;

    this.governance = new ethers.Contract(
      GOVERNANCE_CONTRACT_ADDRESS,
      MyTvGovernanceABI,
      provider,
    );

    this.staking = new ethers.Contract(
      STAKING_CONTRACT_ADDRESS,
      MyTvStakingABI,
      provider,
    );

    this.farming = new ethers.Contract(
      FARMING_CONTRACT_ADDRESS,
      MyTvFarmingABI,
      provider,
    );

    this.publicSale = new ethers.Contract(
      PUBLIC_SALE_CONTRACT_ADDRESS,
      MyTvPublicSaleABI,
      provider,
    );

    this.poolFarming = new ethers.Contract(
      POOL_FARMING_CONTRACT_ADDRESS,
      ERC20_ABI,
      provider,
    );
  }

  /**
   * @returns {Promise<string>}
   */
  async totalSupply() {
    return this.governance.totalSupply();
  }

  /**
   * @returns {Promise<string>}
   */
  async totalStake() {
    const staked = await this.governance.balanceOf(
      STAKING_CONTRACT_ADDRESS,
    );
    return ethers.utils.formatUnits(staked, MYTV);
  }

  /**
   * @returns {Promise<string>}
   */
  async totalPancakeSwapTokens() {
    const staked = await this.governance.balanceOf(
      MY_TV_PANCAKESWAP_ADDRESS,
    );
    return ethers.utils.formatUnits(staked, MYTV);
  }

  /**
   * @returns {Promise<string>}
   */
  async balanceOf(wallet) {
    try {
      const tokens = await this.governance.balanceOf(wallet);
      return ethers.utils.formatUnits(tokens, MYTV);
    } catch (error) {
      return '0';
    }
  }

  /**
   * @returns {Promise<string>}
   */
  async totalUserPendingFarmingReawards(wallet) {
    try {
      const poolLength = await this.farming.poolLength();
      const rewardsP = [];
      for (let i = 0; i < poolLength.toNumber(); i++) {
        rewardsP.push(this.farming.pendingMyTv(i, wallet));
      }
      const rewards = await Promise.all(rewardsP);
      return rewards.reduce(
        (acc, reward) => acc + ethers.utils.formatUnits(reward, MYTV),
        0,
      );
    } catch (error) {
      return '0';
    }
  }

  /**
   * @returns {Promise<string>}
   */
  async totalUserFarming(wallet) {
    try {
      const poolLength = await this.farming.poolLength();
      const farmedsP = [];
      for (let i = 0; i < poolLength.toNumber(); i++) {
        farmedsP.push(this.farming.userInfo(i, wallet));
      }
      const farmeds = await Promise.all(farmedsP);
      // TODO: convert amount and reward dept in mytv token
      return farmeds.reduce((acc, farmed) => {
        return (
          acc +
          (Number(ethers.utils.formatUnits(farmed.amount, MYTV)) -
            Number(ethers.utils.formatUnits(farmed.rewardDebt, MYTV)))
        );
      }, 0);
    } catch (error) {
      return '0';
    }
  }

  /**
   * @returns {Promise<string>}
   */
  async totalUserStakingReawards(wallet) {
    try {
      const poolLength = await this.staking.getLengthOfUserStaking(wallet);

      const rewardsP = Array(poolLength.toNumber()).fill().map((v, i) => {
        return this.staking.getUserRewardLock(wallet, i);
      });

      const rewards = await Promise.all(rewardsP);
      const total = rewards.reduce(
        (acc, reward) => acc.add(reward),
        ethers.BigNumber.from(0),
      );
      return ethers.utils.formatUnits(total, MYTV);
    } catch (error) {
      console.error('4', error);
      return '0';
    }
  }

  async totalUserStakings(wallet) {
    try {
      const poolLength = await this.staking.getLengthOfUserStaking(wallet);
      const stakedP = [];
      for (let i = 0; i < poolLength.toNumber(); i++) {
        stakedP.push(this.staking.staked(wallet, i));
      }
      const stakeds = await Promise.all(stakedP);
      return stakeds.reduce(
        (acc, stake) =>
          acc + Number(ethers.utils.formatUnits(stake.amount, MYTV)),
        0,
      );
    } catch (error) {
      return '0';
    }
  }

  async getStakingPacks() {
    try {
      const lengthOfPacks = await this.staking.getLengthOfStakingPackOptions();
      const packsP = [];
      for (let i = 0; i < lengthOfPacks.toNumber(); i++) {
        packsP.push(this.staking.stakingOptions(i));
      }

      const packs = await Promise.all(packsP);
      let filtered = [];
      filtered = packs.filter(
        (p) => p.rate.toNumber() > 0 && !p.onlyAdmin,
      );

      return filtered.map((p) => ({
        lock: Math.round(p.period.toNumber() / DAY_IN_S),
        apy: p.rate.toNumber(),
      }));
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  /**
   * @type {Promise<ethers.BigNumber>}
   */
  async getTokenPerBlock() {
    return this.farming.mytvPerBlock();
  }

  /**
   * @type {Promise<ethers.BigNumber>}
   */
  async getFarmingBalance() {
    return this.poolFarming.balanceOf(FARMING_CONTRACT_ADDRESS);
  }
}