/* eslint-disable no-await-in-loop */
import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { ethers } from "ethers";
import { useUserStateContext } from "../providers/UserContextProvider";
import { makeFarmingContract } from "../../utils/helpers/makeContracts";
import Table from "../../lib/Table";
import Button from "../../lib/Button";
import FarmingUnstakeModal from "./FarmingUnstakeModal";
import FarmingHarvestModal from "./FarmingHarvestModal";
import useUpdateMyTvBalance from "../../utils/hooks/useUpdateMyTvBalance";
import { useWeb3StateContext } from "../providers/Web3ContextProvier";
import { useMyTvStateContext } from "../providers/MyTvContextProvider";

const Bold = styled.span`
  font-weight: bold;
`;

const head1 = [
  "Pool",
  "LP amount",
  <span>
    Annualized
    <br />
    Interest Rate (APR)
  </span>,
  "MyTV earned",

  <Bold>Operation</Bold>,
];

const ButtonStyle = { backgroundColor: "#08A0F7", width: 104 };

function FarmingsListTable({ active }) {
  const [farmingList, setFarmingList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { farmingApr } = useMyTvStateContext();
  const [areHarvestBtnLoading, setAreHarvestBtnLoading] = useState({});
  const [areHarvestModalLoading, setAreHarvestModalLoading] = useState({});
  const [harvestModalsOpen, setHarvestModalsOpen] = useState(false);

  const [areUnstakeBtnLoading, setAreUnstakeBtnLoading] = useState({});
  const [areUnstakeModalLoading, setAreUnstakeModalLoading] = useState({});
  const [unstakeModalsOpen, setUnstakeModalsOpen] = useState(false);

  const { activeAccount } = useUserStateContext();

  const { web3 } = useWeb3StateContext();

  const farmingContract = useMemo(
    () => (web3 ? makeFarmingContract(web3) : null),
    [web3]
  );

  const setup = useMemo(() => {
    /*
        Il faut d'abord appeler poolLength() dans le smart-contract afin d'avoir le nombre total de pools farming disponibles dans le contrat.
        Puis itérer sur pendingMyTv(<poolID>, <userAddress>) pour voir si 'utilisateur est éligible à des rewards.
        Il faut aussi appeler le mapping userInfo(<poolID>, <userAddress>) qui renvoit l'item amount afin d'afficher le montant en token mis en farming
    */
    if (!web3 || !activeAccount || !farmingContract) return null;

    return async () => {
      const poolsLength = parseInt(
        await farmingContract.methods.poolLength().call(),
        10
      );

      const items = [];
      for (let i = 0; i < poolsLength; i += 1) {
        try {
          const item = await farmingContract.methods
            .userInfo(i, activeAccount)
            .call();

          item.pending = await farmingContract.methods
            .pendingMyTv(i, activeAccount)
            .call();
          item.formatedPending = ethers.utils.formatUnits(item.pending, 4);

          item.id = i;
          item.formatedAmount = ethers.utils.formatUnits(item.amount, 4);
          items.push(item);
        } catch (e) {
          console.log(e);
        }
      }
      setFarmingList(items);
    };
  }, [web3, activeAccount, farmingContract]);

  useEffect(() => {
    if (!active || !setup || farmingList.length !== 0) return;

    (async () => {
      setLoading(true);
      await setup();
      setLoading(false);
    })();
  }, [setup, active, farmingList.length]);
  const updateMyTvBalance = useUpdateMyTvBalance();

  if (!active) return null;

  return (
    <Table
      loading={loading}
      widths={[0.19, 0.19, 0.19, 0.19, 0.24]}
      rows={farmingList.filter((e) => ~~e.amount || ~~e.pending).map((e) => {
        // HARVEST
        const setHarvestModalOpen = (s) =>
          setHarvestModalsOpen((o) => ({ ...o, [e.id]: s }));
        const setHarvestModalLoading = (s) =>
          setAreHarvestModalLoading((o) => ({
            ...o,
            [e.id]: s,
          }));
        const setHarvestBtnLoading = (s) =>
          setAreHarvestBtnLoading((o) => ({ ...o, [e.id]: s }));
        const cleanupHarvest = () => {
          setHarvestBtnLoading(false);
          setHarvestModalOpen(false);
          setHarvestModalLoading(false);
        };

        // UNSTAKE
        const setUnstakeModalOpen = (s) =>
          setUnstakeModalsOpen((o) => ({ ...o, [e.id]: s, farmedData: e }));
        const setUnstakeModalLoading = (s) =>
          setAreUnstakeModalLoading((o) => ({
            ...o,
            [e.id]: s,
          }));
        const setUnstakeBtnLoading = (s) =>
          setAreUnstakeBtnLoading((o) => ({ ...o, [e.id]: s }));
        const cleanupUnstake = () => {
          setUnstakeBtnLoading(false);
          setUnstakeModalOpen(false);
          setUnstakeModalLoading(false);
        };

        return [
          "MYTV/BNB",
          e.formatedAmount,
          `${farmingApr}%`,
          <Bold>{e.formatedPending}</Bold>,
          <Row
            key="cta"
            gutter={[10, 10]}
            justify="space-around"
            align="center"
          >
            <Col>
              <Button
                style={ButtonStyle}
                loading={areHarvestBtnLoading[e.id]}
                onClick={async () => {
                  setHarvestModalOpen(true);
                  setHarvestBtnLoading(true);
                }}
                disabled={e.pending === "0"}
              >
                Harvest
              </Button>
              {harvestModalsOpen[e.id] && (
                <FarmingHarvestModal
                  open
                  loading={areHarvestModalLoading[e.id]}
                  onCancel={() => {
                    if (!areHarvestModalLoading[e.id]) cleanupHarvest();
                  }}
                  onClick={async () => {
                    try {
                      setHarvestModalLoading(true);
                      await farmingContract.methods
                        .withdraw(e.id, 0)
                        .send({ from: activeAccount });
                      setup();
                    } catch (err) {
                      console.log(err);
                    } finally {
                      cleanupHarvest();
                    }
                  }}
                />
              )}
            </Col>
            <Col>
              <Button
                onClick={async () => {
                  setUnstakeModalOpen(true);
                  setUnstakeBtnLoading(true);
                }}
                style={ButtonStyle}
                loading={areUnstakeBtnLoading[e.id]}
              >
                Unstake
              </Button>
              {unstakeModalsOpen[e.id] && (
                <FarmingUnstakeModal
                  farmedData={unstakeModalsOpen.farmedData ?? {}}
                  open
                  loading={areUnstakeModalLoading[e.id]}
                  onCancel={() => {
                    if (!areUnstakeModalLoading[e.id]) cleanupUnstake();
                  }}
                  onSuccess={async ({ amount }) => {
                    /*
                    C'est une petite subtilité que l'on met en place pour les utilisateurs qui ont l'habitude d'utiliser ce type de service farming;
                    Il y a beaucoup d'utilisateurs qui voudront simplement retirer leurs rewards sans pour autant retirer leur farm,
                    du coup dans le cas où la personne veut redeem, on appelle withdraw() avec un amount nul 0
                */
                    try {
                      setUnstakeModalLoading(true);
                      await farmingContract.methods
                        .withdraw(
                          e.id.toString(),
                          ethers.utils.parseUnits(amount, 4)
                        )
                        .send({ from: activeAccount });
                      await updateMyTvBalance();
                      setup();
                    } catch (err) {
                      console.log(err);
                    } finally {
                      cleanupUnstake();
                    }
                  }}
                />
              )}
            </Col>
          </Row>,
        ];
      })}
      head={head1}
    />
  );
}

FarmingsListTable.propTypes = { active: Proptypes.bool };

export default FarmingsListTable;
