import React, { useContext } from "react";
import Proptypes from "prop-types";
import styled, { ThemeContext } from "styled-components";
import { Form } from "antd";
import Input from "../../lib/Input";
import Modal from "../../lib/Modal";
import MyTvButton from "../../lib/MyTvButton";
import inconsistentColors from "../../utils/helpers/inconsistentColors";
import BasicButton from "../../lib/BasicButton";

const dividerColorFn = inconsistentColors(
  "rgba(97, 165, 250, 0.65)",
  "rgba(83, 97, 149, 0.65)"
);

const StyledInputGroup = styled.div`
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  /* color: ${({ theme }) => theme.fg1}; */
  color: ${({ theme }) => theme.textContentModal};
  opacity: 1;
  width: 100%;
  .ant-form-item {
    margin-bottom: 15px !important;
  }
`;

const PopupFooter = styled.div`
  margin-top: 15px;
  width: 100%;
`;

const StyledTitle = styled.div`
  font-size: 14px;
  text-align: left;
  font-weight: lighter;
  margin: 15px 0 8px 0;
  font-family: Circe;
`;
const StyledBalanceMytv = styled.div`
  width: 100%;
  display: block;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-top: 16px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 48px;
    padding-bottom: 32px;
  }
`;

const StyledDivider = styled.div`
  border-top: 1px solid ${dividerColorFn};
  height: 0px;
`;

const StyledDesc = styled.div`
  padding-top: 2px;
  padding-bottom: 23px;

  text-align: center;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textContentModal};
`;

export default function FarmingUnstakeModal({
  open,
  onCancel,
  onSuccess,
  loading,
  farmedData,
}) {
  const theme = useContext(ThemeContext);
  const [form] = Form.useForm();

  return (
    <StyledModal
      width={560}
      title="Withdraw"
      open={open}
      onCancel={onCancel}
      borderColor={dividerColorFn({ theme })}
    >
      <StyledDesc>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </StyledDesc>
      <StyledDivider />
      <Form
        form={form}
        onFinish={() => {
          if (onSuccess) onSuccess({ amount: form.getFieldValue("amount") });
        }}
      >
        <StyledInputGroup>
          <StyledTitle>Please input the amount</StyledTitle>
          <Form.Item
            name="amount"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.reject(new Error("required"));
                  if (parseFloat(value) <= 0.0001)
                    return Promise.reject(new Error("0.001 minimal."));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="The amount"
              suffix={
                <BasicButton
                  $disableShadows
                  onClick={() => {
                    form.setFieldsValue({ amount: farmedData.formatedAmount ?? 0 });
                  }}
                >
                  Max
                </BasicButton>
              }
              borderWidth={2}
              borderColor="#2E86FF"
              isNumber
            />
          </Form.Item>
          <StyledBalanceMytv>
            <div>
              Max : {farmedData.formatedAmount ?? 0} LP <br />
            </div>
          </StyledBalanceMytv>
        </StyledInputGroup>
        <PopupFooter>
          <MyTvButton
            style={{
              width: "70%",
              textTransform: "uppercase",
              margin: "auto",
              marginTop: 29,
            }}
            onClick={() => form.submit()}
            loading={loading}
          >
            UNSTAKE
          </MyTvButton>
        </PopupFooter>
      </Form>
    </StyledModal>
  );
}

FarmingUnstakeModal.propTypes = {
  open: Proptypes.bool,
  onCancel: Proptypes.func,
  onSuccess: Proptypes.func,
  loading: Proptypes.bool,
  farmedData: Proptypes.object,
};
